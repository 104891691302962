<!-- Hero -->
<section>
  <zv-hero></zv-hero>
</section>
<!-- / Hero -->
<!-- Stat list -->
<section>
  <div class="md:flex md:flex-row">
    <div class="md:basis-1/12"></div>
    <div class="md:basis-10/12">
      <zv-stat-list></zv-stat-list>
    </div>
    <div class="md:basis-1/12"></div>
  </div>
</section>
<!-- / Stat list -->
<!-- My primary strengths -->
<section id="my-primary-strengths">
  <zv-heading-link
    text="My primary strengths"
    href="#my-primary-strengths"
    [hasReducedPaddingTop]="false"
  ></zv-heading-link>
  <div class="md:flex md:flex-row">
    <div class="md:basis-1/12 lg:basis-2/12"></div>
    <div class="md:basis-10/12 lg:basis-8/12 lg:px-10">
      <zv-primary-strengths-list></zv-primary-strengths-list>
    </div>
    <div class="md:basis-1/12 lg:basis-2/12"></div>
  </div>
</section>
<!-- / My primary strengths -->
<!-- My knowledge roadmap -->
<section id="my-knowledge-roadmap">
  <zv-heading-link
    text="My knowledge roadmap"
    href="#my-knowledge-roadmap"
    [hasReducedPaddingTop]="false"
  ></zv-heading-link>
  <div class="md:flex md:flex-row">
    <div class="md:basis-1/12 lg:basis-2/12"></div>
    <div class="md:basis-10/12 lg:basis-8/12 lg:px-10">
      <div class="sub-intro">
        <p class="mb-0">
          The knowledge I have acquired through the years has been assembled
          into a knowledge roadmap. The roadmap is very much alive, as I am
          interested in learning more.
        </p>
      </div>
      <zv-loading-indicator
        *ngIf="roadmapList.length === 0"
      ></zv-loading-indicator>
      <div *ngIf="roadmapList.length > 0">
        <div class="text-center pb-12">
          <button
            class="mb-2 min-w-[14rem] md:min-w-[16rem] md:text-base"
            *ngIf="roadmapList.length > 0"
            (click)="
              isRoadmapExpanded ? collapseRoadmapList() : expandRoadmapList()
            "
          >
            {{ isRoadmapExpanded ? 'Collapse' : 'Expand' }} all
          </button>
          <p class="roadmap-alert leading-tight">
            <small>
              <ng-icon
                class="roadmap-alert-icon"
                name="featherAlertTriangle"
                strokeWidth="1"
                size="16"
                [ngStyle]="{
                  transform: 'translateX(2px) translateY(3px)'
                }"
              ></ng-icon
              >&nbsp; CAUTION: Very long list ahead!
            </small>
          </p>
        </div>
        <div class="md:px-28 lg:px-20 xl:px-40">
          <ng-container #roadmap>
            <!-- TODO
          appShowWhenInViewport
          #intersection="intersection"
          (isIntersecting)="isIntersecting($event)"
             -->
            <!-- <zv-loading-indicator
          [isVisible]="roadmapList.length === 0"
        ></zv-loading-indicator>
             *ngIf="roadmapList.length > 0"-->
            <zv-roadmap-list [list]="roadmapList"></zv-roadmap-list>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="md:basis-1/12 lg:basis-2/12"></div>
  </div>
</section>
<!-- / My knowledge roadmap -->
<!-- My digital skills certification -->
<section id="my-digital-skills-certification">
  <zv-heading-link
    text="My digital skills certification"
    href="#my-digital-skills-certification"
    [hasReducedPaddingTop]="true"
  ></zv-heading-link>
  <div class="md:flex md:flex-row">
    <div class="md:basis-1/12 lg:basis-2/12"></div>
    <div class="md:basis-10/12 lg:basis-8/12 mb-10 md:mb-2 lg:px-6">
      <div class="sub-intro">
        <p class="mb-0">
          I have successfully cleared assessment for the following skills by
          accepting different challenges, such as code challenges, courses,
          questionnaires or involvements.
        </p>
      </div>
      <div class="certificate-list">
        <zv-certificate
          *ngFor="let certificate of certificateList"
          [linkUrl]="certificate.linkUrl"
          [linkTitle]="certificate.linkTitle"
          [title]="certificate.title"
          [type]="certificate.type"
          [issuedBy]="certificate.issuedBy"
          [duration]="certificate.duration"
          >{{ certificate.title }}</zv-certificate
        >
      </div>
    </div>
    <div class="md:basis-1/12 lg:basis-2/12"></div>
  </div>
</section>
<!-- / My digital skills certification -->
<!-- My visited conferences -->
<section id="my-visited-conferences">
  <zv-heading-link
    text="My visited conferences"
    href="#my-visited-conferences"
    [hasReducedPaddingTop]="true"
  ></zv-heading-link>
  <div class="md:flex lg:flex-row">
    <div class="md:basis-1/12 lg:basis-2/12"></div>
    <div class="md:basis-10/12 lg:basis-8/12 lg:px-10">
      <div class="sub-intro">
        <p class="mb-0">
          When traveling, I enjoy meeting like-minded individuals and industry
          peers, exchanging views on shared challenges, building my network, and
          having fun.
        </p>
      </div>
      <zv-conference-list
        [list]="conferenceList"
        text="Where to next?"
      ></zv-conference-list>
    </div>
    <div class="md:basis-1/12 lg:basis-2/12"></div>
  </div>
</section>
<!-- / My visited conferences -->
<!-- My deep dive handbook -->
<section id="my-deep-dive-handbook">
  <zv-heading-link
    text="My deep dive handbook"
    href="#my-deep-dive-handbook"
    [hasReducedPaddingTop]="true"
  ></zv-heading-link>
  <div class="md:flex md:flex-row">
    <div class="md:basis-1/12 lg:basis-2/12"></div>
    <div class="md:basis-10/12 lg:basis-8/12 mb-10 md:mb-2 lg:px-6">
      <div class="sub-intro mb-0">
        <p class="mb-0">
          Explore my
          <zv-external-link
            size="small"
            linkTitle="Deep dive handbook"
            linkUrl="https://handbook.zigavukcevic.dev"
            >Deep dive handbook</zv-external-link
          >
          — featuring practical tips, concepts, techniques, and interactive
          playgrounds for modern web development.
        </p>
      </div>
    </div>
    <div class="md:basis-1/12 lg:basis-2/12"></div>
  </div>
</section>
<!-- / My deep dive handbook -->

<!-- Contact me -->
<!-- <section>
  <h2 class="text-center">Contact me</h2>
  <div class="lg:flex lg:flex-row">
    <div class="lg:basis-2/12"></div>
    <div class="lg:basis-8/12">
      <div class="sub-intro">
        <p class="mb-0">
          Drop me a message by using the contact form below. Whether you have a
          question, want to share an idea, or just say hello, I'm all ears.
        </p>
      </div>
      <zv-contact></zv-contact>
    </div>
    <div class="lg:basis-2/12"></div>
  </div>
</section> -->
<!-- / Contact me -->

<!-- <div style="background-color: white;">
  <div class="md:flex md:flex-row mt-16"> -->
<!-- Find me at -->
<!-- <div class="basis-1/3">
      <h2
        class="text-black text-2xl md:text-3xl mb-10 pt-3 pl-2"
      >
        Find me at
      </h2>

      <ul class="ml-9 mb-16">
        <li class="mb-2">
          <a
            title="LinkedIn"
            href="https://www.linkedin.com/in/ziga-vukcevic/"
            appExternalLink
            ><i class="lab la-linkedin-in text-xl"></i> LinkedIn</a>
        </li>
        <li class="mb-2">
          <a
            title="Twitter"
            href="https://twitter.com/ZigaVukcevicDev"
            appExternalLink
            ><i class="lab la-twitter text-xl"></i> Twitter</a>
        </li>
        <li class="mb-2">
          <a
            title="GitHub"
            href="https://github.com/ZigaVukcevicDev"
            appExternalLink
            ><i class="lab la-github text-xl"></i> GitHub</a>
        </li>
      </ul>
    </div> -->
<!-- / Find me at -->
