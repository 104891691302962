<!-- <header class="container mx-auto relative">
  <nav>
    <ul>
      <li>Link 1</li>
      <li>Link 2</li>
      <li>Link 3</li>
    </ul>
  </nav>
</header> -->
<div class="main-image-container">
  <img
    src="assets/man-with-laptop/sun-rays-209a1f00a5f9cf7b.svg"
    alt="Sun rays"
    class="main-image-sun-rays"
    [ngClass]="{
      animate: areImagesLoaded$ | async
    }"
    width="550"
    height="555"
    zvImageLoadVerifier
  />
  <img
    src="assets/man-with-laptop/sun-circle-6c849ad362e2797e.svg"
    alt="Sun circle"
    class="main-image-sun-circle"
    width="812"
    height="817"
    zvImageLoadVerifier
  />
  <img
    src="assets/man-with-laptop/man-339af8bb3c89179d.svg"
    alt="Man"
    class="main-image-man"
    width="584"
    height="537"
    zvImageLoadVerifier
  />
  <img
    src="assets/man-with-laptop/t-shirt-a4741f6ad159af9c.webp"
    alt="T-shirt"
    class="main-image-t-shirt"
    width="280"
    height="139"
    zvImageLoadVerifier
  />
</div>
<div class="main-container mx-auto px-6">
  <!-- Page content -->
  <main>
    <router-outlet></router-outlet>
  </main>
  <!-- / Page content -->
  <!-- Footer -->
  <footer class="mb-5 text-center">
    <p class="mb-2">
      Source code of this site is hosted on
      <zv-external-link
        size="small"
        linkTitle="Azure DevOps Services"
        linkUrl="https://azure.microsoft.com/en-us/services/devops/"
        >Azure DevOps Services</zv-external-link
      >. Within CI/CD process, the site has been built using
      <zv-external-link
        size="small"
        linkTitle="Angular"
        linkUrl="https://angular.io/"
        >Angular</zv-external-link
      >
      and deployed to
      <zv-external-link
        size="small"
        linkTitle="Azure Static Web Apps"
        linkUrl="https://azure.microsoft.com/en-us/services/app-service/static/"
        >Azure Static Web Apps</zv-external-link
      >.
      <zv-external-link
        size="small"
        linkTitle="Azure Functions"
        linkUrl="https://azure.microsoft.com/en-us/services/functions/"
        >Azure Functions</zv-external-link
      >
      have been used for the API purpose. Unit tests are covering
      {{ unitTestsTotalCodeCoverage }}% of code.
      <small class="mt-3 mb-5"
        >&copy; 2015 &mdash; today | zigavukcevic.dev | v{{ appVersion }}</small
      >
    </p>
  </footer>
  <!-- / Footer -->
</div>
