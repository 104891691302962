<a
  [title]="linkTitle"
  routerLink=""
  (click)="openLightbox($event)"
  class="certificate"
  data-test="certificate-link"
>
  <span class="top">
    <ng-icon
      class="badge"
      name="featherAward"
      strokeWidth="1"
      size="28"
      [ngStyle]="{
        transform: 'translateX(5px) translateY(0px)'
      }"
    ></ng-icon>
    <span class="segment left"></span>
    <span class="segment right"></span>
  </span>
  <span class="main">
    <span class="title" data-test="certificate-title">{{ title }}</span>
    <span class="info" data-test="certificate-info">
      <span class="uppercase">{{ type }}</span>
      <br />
      by {{ issuedBy
      }}<ng-icon
        name="featherExternalLink"
        strokeWidth="1"
        [ngStyle]="{
          transform: 'translateX(2px) translateY(1px)'
        }"
      ></ng-icon>
      <br />
      <span class="duration" data-test="certificate-duration">
        {{ duration }}
      </span>
    </span>
  </span>
</a>

<div
  class="certificate-lightbox"
  [class.active]="isLightboxOpen"
  (click)="closeLightbox()"
>
  <button class="close-button" (click)="closeLightbox()">
    <ng-icon name="featherX" size="24" strokeWidth="1"></ng-icon>
  </button>
  <img
    [src]="linkUrl"
    [alt]="title"
    class="image"
    (click)="$event.stopPropagation()"
  />
</div>
