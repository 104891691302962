import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgIconsModule } from '@ng-icons/core';
import {
  featherAlertTriangle,
  featherAward,
  featherChevronDown,
  featherChevronUp,
  featherExternalLink,
  featherHash,
  featherMic,
  featherMonitor,
  featherSend,
  featherUser,
  featherVolume2,
  featherX,
} from '@ng-icons/feather-icons';
import * as Sentry from '@sentry/angular';
import { IgnoreLastWordPipe } from 'src/app/utils/pipes/ignore-last-word.pipe';
import { TakeLastWordPipe } from 'src/app/utils/pipes/take-last-word.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CertificateComponent } from './certificate/certificate.component';
import { ConferenceItemComponent } from './conference/item/conference-item.component';
import { ConferenceListComponent } from './conference/list/conference-list.component';
import { ContactComponent } from './contact/contact.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { InputComponent } from './form/field/input/input.component';
import { TextAreaComponent } from './form/field/text-area/text-area.component';
import { ValidationComponent } from './form/validation/validation.component';
import { HeadingLinkComponent } from './heading-link/heading-link.component';
import { HeroComponent } from './hero/hero.component';
import { ImageLoadVerifierDirective } from './image-load-verifier/directive/image-load-verifier.directive';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { ObserveIntersectionDirective } from './observe-visibility/observe-intersection.directive';
import { PageHomeComponent } from './page/home/home.component';
import { PageStyleGuideComponent } from './page/style-guide/style-guide.component';
import { PrimaryStrengthsListComponent } from './primary-strengths-list/primary-strengths-list.component';
import { RoadmapItemComponent } from './roadmap/item/roadmap-item.component';
import { RoadmapListComponent } from './roadmap/list/roadmap-list.component';
// import { RoadmapService } from './roadmap/service/roadmap.service';
import { RoadmapService } from 'src/app/roadmap/service/roadmap.service';
import { StatListComponent } from './stat-list/stat-list.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    // FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgIconsModule.withIcons({
      featherExternalLink,
      featherX,
      featherChevronUp,
      featherChevronDown,
      featherSend,
      featherMonitor,
      featherMic,
      featherUser,
      featherVolume2,
      featherAward,
      featherAlertTriangle,
      featherHash,
    }), // NOTE: see all available icons at https://feathericons.com/
  ],
  declarations: [
    AppComponent,
    PageHomeComponent,
    PageStyleGuideComponent,
    StatListComponent,
    RoadmapItemComponent,
    RoadmapListComponent,
    CertificateComponent,
    LoadingIndicatorComponent,
    ConferenceItemComponent,
    ExternalLinkComponent,
    ConferenceListComponent,
    HeroComponent,
    PrimaryStrengthsListComponent,
    ObserveIntersectionDirective,
    ContactComponent,
    InputComponent,
    ValidationComponent,
    TextAreaComponent,
    IgnoreLastWordPipe,
    TakeLastWordPipe,
    LoadingIndicatorComponent,
    HeadingLinkComponent,
    ImageLoadVerifierDirective,
  ],
  providers: [
    // Sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    RoadmapService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
