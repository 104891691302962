import { Component, Input, OnDestroy } from '@angular/core';
import ICertificate from './interface/certificate.interface';

@Component({
  selector:
    'zv-certificate[linkUrl][linkTitle][title][type][issuedBy][duration]',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent implements OnDestroy {
  @Input() linkUrl!: ICertificate['linkUrl'];
  @Input() linkTitle!: ICertificate['linkTitle'];
  @Input() title!: ICertificate['title'];
  @Input() type!: ICertificate['type'];
  @Input() issuedBy!: ICertificate['issuedBy'];
  @Input() duration!: ICertificate['duration'];

  isLightboxOpen = false;

  openLightbox(event: Event) {
    event.preventDefault();
    this.isLightboxOpen = true;
    document.body.classList.add('overflow-hidden');
  }

  closeLightbox() {
    this.isLightboxOpen = false;
    document.body.classList.remove('overflow-hidden');
  }

  ngOnDestroy() {
    if (this.isLightboxOpen) {
      document.body.classList.remove('overflow-hidden');
    }
  }
}
