<ul>
  <li>
    Mastery of HTML, CSS, JavaScript, and TypeScript, including
    <strong>Angular</strong> framework and experience with
    <strong>RxJS</strong>, <strong>NgRx</strong> and Nx.
  </li>
  <li>
    Elevation of <strong>user experience (UX)</strong> through intuitive
    <strong>user interface (UI)</strong>, with the ability to
    <strong>translate designs</strong> into semantic
    <strong>front-end</strong> code with a <strong>careful eye</strong>.
  </li>
  <li>
    Deep knowledge of <strong>responsive design</strong> principles, with an
    appreciation for <strong>color and typography</strong>.
  </li>
  <li>
    Creating
    <strong>high-quality, reusable components</strong> and libraries, ensuring
    codebase <strong>quality and scalability</strong>.
  </li>
  <li>
    Knowledge of <strong>testing frameworks</strong>, such as Jest or Jasmine,
    and Cypress, and <strong>CI/CD processes</strong>.
  </li>
  <li>
    Strong <strong>critical thinking</strong> and
    <strong>problem-solving skills.</strong>
  </li>
  <li>
    Ability to <strong>work effectively</strong> as part of a team, providing
    feedback and <strong>collaborating</strong> with cross-functional teams.
  </li>
  <li>
    <strong>Adaptation</strong> to new technologies and
    <strong>quickly learn</strong> new skills.
  </li>
</ul>
